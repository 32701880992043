@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  color: #fff;
  text-align: center;
  font-weight: 300;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  font-size: 2.8em;
  margin-top: 1.5em;
}
.title small {
  display: block;
  font-size: 0.6em;
  margin-top: 0.4em;
}
.window {
  width: 100%;
  background: GhostWhite;
  border-radius: 0.3rem;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.window .window-header {
  height: 37px;
  background: Gainsboro;
  position: relative;
}
.window .window-header .action-buttons {
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -5px;
  width: 10px;
  height: 10px;
  background: Crimson;
  border-radius: 50%;
  box-shadow: 15px 0 0 Orange,
				30px 0 0 LimeGreen;
}
.window .window-header .language {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -10px;
  height: 21px;
  padding: 0 1em;
  text-align: right;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
  font-family: Lato, sans-serif;
  color: DimGrey;
}
.window .window-body {
  position: relative;
  height: 360px;
}
.window .window-body .code-input,
.window .window-body .code-output {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: none;
  font-family: 'PT Mono', monospace;
  font-size: 0.8rem;
  background: transparent;
  white-space: pre-wrap;
  line-height: 1.5em;
  word-wrap: break-word;
}
.window .window-body .code-input {
  opacity: 0.7;
  margin: 0;
  color: #999;
  resize: none;
  height: 100%;
}
.window .window-body .code-output {
  pointer-events: none;
  z-index: 3;
  margin: 0;
  overflow-y: auto;
}
.window .window-body .code-output code {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 1rem;
  display: block;
  color: #666;
  font-size: 0.8rem;
  font-family: 'PT Mono', monospace;
}
a.token {
  text-decoration: none;
}
.token.selector,
.token.punctuation,
.token.keyword {
  color: PaleVioletRed;
}
.token.property,
.token.number,
.token.string,
.token.punctuation,
.token.tag-id {
  color: DodgerBlue;
}
.token.function,
.token.attr-name {
  color: CadetBlue;
}
.token.atrule .atrule-id {
  color: BlueViolet;
}
.token.boolean {
  color: LightSlateGray;
}
.token.comment {
  color: DarkGrey;
}
.language-php .variable {
  color: CadetBlue;
}

.yellow-bg {
background: rgb(245,202,10);
background: linear-gradient(90deg, rgba(245,202,10,1) 35%, rgba(230,182,80,1) 100%);
}

.shape {
  -webkit-transform: translate(10%,-30%) skewY(20deg);
  transform: translate(10%,-30%) skewY(20deg);
  background: rgb(245,202,10);
background: linear-gradient(90deg, rgba(245,202,10,1) 35%, rgba(230,182,80,1) 100%);

  top: 10vh;
  left: 0;
  width: 80%;
  z-index: -1;
  position: absolute;
  border-radius: 100px;
}

.shape-bg {
  -webkit-transform: translate(10%,-30%) skewY(10deg);
  transform: translate(10%,-30%) skewY(10deg);
  background: rgb(245,202,10);
  background: linear-gradient(90deg, rgba(245,202,10,1) 35%, rgba(230,182,80,1) 100%);
  bottom: 0;
  left: 0;
  width: 80%;
  z-index: -1;
  position: absolute;
  border-radius: 100px;
}
